import React, { FC } from 'react'
import cx from 'classnames'

import { ContentSlice, CallToAction, Padding } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import StoryCTA from '../../components/StoryCTA'

import styles from './styles.module.scss'

interface Props {
  className?: string

  slice: ContentSlice
  callToAction: CallToAction
  padding: Padding
}

const Content: FC<Props> = ({ className, slice, callToAction, padding }) => {
  // Only show the first item in this mode.
  const item = slice.items[0]
  const srcset = [
    { img: item.image?.mobile_1x, size: '1x' },
    { img: item.image?.mobile_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  const style: any = { backgroundColor: slice.primary?.story_background || '' }
  if (padding.top) style.paddingTop = padding.top
  if (padding.bottom) style.paddingBottom = padding.bottom
  if (padding.left) style.paddingLeft = padding.left
  if (padding.right) style.paddingRight = padding.right

  return (
    <div className={cx(styles.content, className)} style={style}>
      <div className={styles.inner}>
        <PresentRichText className={styles.text} render={item.story_content} />
        <img
          className={cx(styles.image, styles.mobile)}
          src={item.image.url}
          srcSet={srcset}
          alt={item.image?.alt || ''}
        />
        <PresentRichText className={styles.imageCaption} render={item.story_image_caption} />
        {callToAction?.url && (
          <>
            <div className={styles.buffer} />
            <StoryCTA className={styles.cta} {...callToAction} />
          </>
        )}
      </div>
    </div>
  )
}

export default Content
