import React, { FC, useRef, useCallback, useMemo, MouseEvent, TouchEvent } from 'react'
import cx from 'classnames'

import { trackSync } from 'utils/analytics'

import { CallToAction } from '../../types'

import FlourishIcon from 'svgs/flourish'

import Button from 'components/Core/CoreButton'

import styles from './styles.module.scss'

interface Props extends CallToAction {
  className?: string
}

// Story CTA needs to handle mouseDown/Up specifically because the
// storymode captures mousedown/up to allow navigation. Therefore
// onClick will never be fired.
const StoryCTA: FC<Props> = ({ className, text, url, analytics_event, analytics_module }) => {
  const isDownOnRef = useRef(false)

  const handleMouseDown = useCallback(() => {
    isDownOnRef.current = true
  }, [])

  const handleMouseUp = useCallback(
    (event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      if (isDownOnRef.current) {
        isDownOnRef.current = false
        event.preventDefault()
        event.stopPropagation()

        // Use track sync to ensure the tracking goes through before
        // we redirect them.
        // We can't use async await here because we need to stop the propagation
        // above.
        trackSync('click cta', { cta: analytics_event, module: analytics_module, location: 'desktop-left-column' })

        // If this is a screen in the mobile app, the webview is hooked on using
        // this variable. In this case, we will post to that instead of following
        // the CTA, so that the mobile app can do something different.
        const rnwv = (window as any).ReactNativeWebView
        if (rnwv) {
          rnwv.postMessage(`click_cta:${JSON.stringify(url)}`)
        } else {
          if (!url) return

          if (url && url.target === '_blank') {
            window.open(url.url, '_blank')?.focus()
          } else {
            window.location.href = url?.url || ''
          }
        }
      }
    },
    [url, analytics_event, analytics_module]
  )

  const showCta = useMemo(() => {
    if (!window) return false

    const params = new URLSearchParams(window.location.href)

    return params.has('show_cta')
  }, [])

  if (!showCta) return null

  return (
    <Button
      className={cx(styles.storyCta, className)}
      type="button"
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
      icon={<FlourishIcon />}
      text={text || 'Get Started'}
    />
  )
}

export default StoryCTA
