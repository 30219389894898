import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import { Participant } from '../../types'
import useParticipants from './useParticipants'

import Image from './Image'

import styles from './styles.module.scss'

interface Props {
  className?: string
  identifier: string
}

const Participant: FC<Props> = ({ className, identifier }) => {
  const { findParticipant } = useParticipants()

  const participant = useMemo(() => findParticipant(identifier), [identifier, findParticipant])

  return (
    <div className={cx(styles.participant, className)}>
      {participant ? <Image participant={participant} /> : <span className={styles.missing} />}
      <div className={styles.info}>
        <span className={styles.name}>{participant?.display_name}</span>
        <span className={styles.title}>{participant?.display_title}</span>
      </div>
    </div>
  )
}

export default Participant
