import React, { FC } from 'react'

import { RelatedStudy } from '../../types'

import CaseStudyLink from './CaseStudyLink'

import styles from './styles.module.scss'

interface Props {
  studies: Array<RelatedStudy>
}

const CTA: FC<Props> = ({ studies }) => {
  // No studies? No render!
  if (studies.length == 0) return null

  return (
    <div className={styles.moreCaseStudies}>
      <h1>{'More Case Studies'}</h1>
      <ul>
        {studies.map((study, i) => (
          <CaseStudyLink key={i} {...study} />
        ))}
      </ul>
    </div>
  )
}

export default CTA
