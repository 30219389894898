import React, { FC } from 'react'
import cx from 'classnames'

import { Participant } from '../../types'

import styles from './styles.module.scss'

interface Props {
  className?: string
  participant: Participant
  big?: boolean
}

const Image: FC<Props> = ({ className, participant, big }) => {
  const srcset = [
    { img: big ? participant.image.big_1x : participant.image, size: '1x' },
    { img: big ? participant.image.big_2x : participant.image?.image_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  return (
    <img
      className={cx(styles.image, { [styles.big]: big }, className)}
      src={participant.image.url}
      srcSet={srcset}
      alt={participant.image?.alt || ''}
    />
  )
}

export default Image
