import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import { Participant } from '../../types'
import useParticipants from './useParticipants'

import Image from './Image'

import styles from './styles.module.scss'

interface Props {
  className?: string
  identifiers?: Array<string>
}

const ImageList: FC<Props> = ({ className, identifiers }) => {
  const { all, findParticipant } = useParticipants()

  const participants = useMemo(() => {
    if (typeof identifiers === 'undefined') return all
    return identifiers.map((identifier) => findParticipant(identifier)).filter((participant) => !!participant) as Array<
      Participant
    >
  }, [identifiers, findParticipant, all])

  return (
    <ul className={cx(styles.imageList, className)}>
      {participants.map((participant) => (
        <li key={participant?.identifier}>
          <Image participant={participant} />
        </li>
      ))}
    </ul>
  )
}

export default ImageList
