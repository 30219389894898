import Container from './Container'
import { GlobalContext, SectionsContext } from './context'
import { SectionI, Padding } from './types'
import React, { FC, useEffect, useMemo, useState } from 'react'

export interface InstaStoryProps {
  currentIndex?: number
  defaultInterval?: number
  isPaused?: boolean
  loop?: boolean
  onAllSectionsEnd?: (index: number, sections: SectionI[]) => void
  onPause?: (index: number) => void
  onPrevious?: (index: number) => void
  onSectionEnd?: (index: number, section: SectionI) => void
  onSectionStart?: (index: number, section: SectionI) => void
  onSkip?: (index: number) => void
  onChange?: (index: number) => void
  sections: SectionI[]
  hud?: (section: SectionI) => React.ReactNode
  progressClass?: string
  padding?: Padding
}

const InstaStory: FC<InstaStoryProps> = ({
  currentIndex,
  defaultInterval = 4000,
  isPaused,
  loop,
  onAllSectionsEnd,
  onPause,
  onPrevious,
  onSectionEnd,
  onSectionStart,
  onSkip,
  onChange,
  sections,
  hud,
  progressClass,
  padding,
}) => {
  const context = useMemo(() => {
    return {
      currentIndex,
      defaultInterval,
      loop,
      onAllSectionsEnd,
      onPause,
      onPrevious,
      onSectionStart,
      onSectionEnd,
      onSkip,
      onChange,
    }
  }, [
    currentIndex,
    defaultInterval,
    loop,
    onAllSectionsEnd,
    onPause,
    onPrevious,
    onSectionStart,
    onSectionEnd,
    onSkip,
    onChange,
  ])

  const [innerSections, setInnerSections] = useState({ sections })

  useEffect(() => {
    setInnerSections({ sections })
  }, [sections])

  return (
    <GlobalContext.Provider value={context}>
      <SectionsContext.Provider value={innerSections}>
        <Container isPaused={isPaused || false} hud={hud} progressClass={progressClass} padding={padding || {}} />
      </SectionsContext.Provider>
    </GlobalContext.Provider>
  )
}

export default InstaStory
