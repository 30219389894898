import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.0004 1.94812L19.0832 2.03091V2.64749C19.0832 4.37087 19.3359 4.62361 21.0615 4.62578H21.6759L21.7587 4.70858V5.1465L21.6759 5.22929H21.0593C19.3359 5.22929 19.0854 5.48203 19.0832 7.20758V7.82199L19.0004 7.90478H18.5625L18.4797 7.82199V7.2054C18.4797 5.48203 18.227 5.22929 16.5014 5.22711H15.887L15.8042 5.14432V4.7064L15.887 4.62361H16.5036C18.227 4.62361 18.4775 4.37087 18.4797 2.64531V2.03091L18.5625 1.94812H19.0004Z"
      fill="currentColor"
    />
    <path
      d="M11.3691 3.4305H12.6328L12.8677 3.66547V5.43861C12.8677 10.4026 13.5983 11.1292 18.5662 11.1292L20.3354 11.1351L20.5704 11.3701V12.6338L20.3354 12.8688H18.5623C13.5983 12.8688 12.8717 13.5994 12.8717 18.5673L12.8658 20.3365L12.6308 20.5715H11.3671L11.1321 20.3365V18.5634C11.1321 13.5994 10.4015 12.8727 5.4336 12.8727L3.66441 12.8668L3.42944 12.6318V11.3681L3.66441 11.1332H5.43755C10.4015 11.1332 11.1282 10.4026 11.1282 5.43466L11.1341 3.6635L11.3691 3.42853V3.4305Z"
      fill="currentColor"
    />
  </svg>
)

export default Svg
