import React, { FC } from 'react'
import { RichTextBlock } from 'prismic-reactjs'

import { StoryImage, Padding } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props {
  className?: string

  image: StoryImage
  deck: Array<RichTextBlock>
  deckEnd: Array<RichTextBlock>
  background: string
  padding: Padding
}
const Section: FC<Props> = ({ image, deck, deckEnd, background, padding }) => {
  const srcsetMobile = [
    { img: image, size: '1x' },
    { img: image?.image_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  const style: any = { backgroundColor: background }
  if (padding.top) style.paddingTop = padding.top
  if (padding.bottom) style.paddingBottom = padding.bottom
  if (padding.left) style.paddingLeft = padding.left
  if (padding.right) style.paddingRight = padding.right

  return (
    <div className={styles.introPage} style={style}>
      <div className={styles.inner}>
        <PresentRichText className={styles.title} render={deck} />
        <img className={styles.image} src={image.url} srcSet={srcsetMobile} alt={image?.alt || ''} />
        <PresentRichText className={styles.name} render={deckEnd} />
      </div>
    </div>
  )
}

export default Section
