import React, { FC, useCallback } from 'react'
import cx from 'classnames'

import { track } from 'utils/analytics'

import { CallToAction } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import Button from 'components/NikhilButton'

import styles from './styles.module.scss'

interface Props extends CallToAction {
  className?: string
}

const Footer: FC<Props> = ({ className, copy, text, url, analytics_event, analytics_module }) => {
  const handleCtaClick = useCallback(async () => {
    await track('click cta', { cta: analytics_event, module: analytics_module, location: 'footer' })

    if (!url) return

    if (url && url.target === '_blank') {
      window.open(url.url, '_blank')?.focus()
    } else {
      window.location.href = url?.url || ''
    }
  }, [url, analytics_event, analytics_module])

  return (
    <div className={cx(styles.footer, className)}>
      <div className={styles.body}>
        <div className={styles.corset}>
          <PresentRichText className={styles.copy} render={copy} />
          <Button className={styles.button} type="button" onClick={handleCtaClick}>
            {text || 'Get Started'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Footer
