import cx from 'classnames'
import React, { ForwardRefRenderFunction, PropsWithChildren, forwardRef } from 'react'
import styles from '../styles.module.scss'

interface SectionProps {
  className?: string
  webview: boolean
}

const Section: ForwardRefRenderFunction<HTMLElement, PropsWithChildren<SectionProps>> = (
  { className, children, webview },
  ref
) => {
  return (
    <section
      ref={ref}
      className={cx(
        'tw-relative tw-w-full tw-font-sans-open-sans tw-flex tw-flex-col tw-items-center tw-overflow-hidden',
        className,
        {
          [styles.sectionInWebview]: webview,
          [styles.sectionInBrowser]: !webview,
        }
      )}
    >
      {children}
    </section>
  )
}

export default forwardRef(Section)
