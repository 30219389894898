import React, { FC, useEffect } from 'react'
import cx from 'classnames'

import { trackPage } from 'utils/analyticsV2'

import { ProjectCaseStudyData, Padding } from './types'

import Header from 'components/MarketingSite/Header'

import { ParticipantsManager } from './components/Participant/useParticipants'
import CTA from './components/CTA'
import MoreCaseStudies from './components/MoreCaseStudies'
import Footer from './components/Footer'

import Article from './Article'
import Story from './Story'

import styles from './styles.module.scss'

const ProjectCaseStudy: FC<{
  data: ProjectCaseStudyData
  inApp: boolean
  inAppStory: boolean
  loggedIn: boolean
  padding: Padding
}> = ({
  data: {
    main_image,
    story_intro_image,
    study_title,
    deck,
    story_deck,
    story_deck_end,
    context,
    body,

    story_title_background,
    story_intro_background,

    call_to_action,

    customers,
    advisors,
    related_studies,
    download_app_link,
  },
  inApp,
  inAppStory,
  loggedIn,
  padding,
}) => {
  useEffect(() => {
    trackPage('project_case_study')
  }, [])

  return (
    <ParticipantsManager participants={{ advisors: advisors, customers: customers }}>
      <div className={styles.caseStudy}>
        {inAppStory ? (
          <Story
            className={styles.mainColumn}
            image={main_image}
            introImage={story_intro_image}
            title={study_title}
            deck={story_deck}
            deckEnd={story_deck_end}
            context={context}
            titleBackground={story_title_background}
            introBackground={story_intro_background}
            slices={body}
            padding={padding}
            callToAction={call_to_action}
          />
        ) : (
          <>
            {!inApp && (
              <Header
                className={styles.header}
                logoClassName={styles.logo}
                showDownloadApp={download_app_link?.url}
                hideSignupButton
                hideSignInButton={loggedIn}
                hideDashboardButton={!loggedIn}
              />
            )}
            <div className={cx(styles.body, { [styles.inAppView]: inApp || inAppStory })}>
              <div className={cx(styles.ctaColumn, styles.desktop)}>
                <CTA {...call_to_action} />
              </div>
              <Article
                className={styles.mainColumn}
                image={main_image}
                title={study_title}
                deck={deck}
                context={context}
                slices={body}
              />
              <div className={cx(styles.moreStudiesColumn, styles.desktop)}>
                <MoreCaseStudies studies={related_studies} />
              </div>
            </div>
            <Footer {...call_to_action} />
          </>
        )}
      </div>
    </ParticipantsManager>
  )
}

export default ProjectCaseStudy
