import { GlobalContext, SectionsContext } from './context'
import ProgressArray from './ProgressArray'
import { GlobalContextI, SectionsContextI, SectionI, Padding } from './types'
import React, { FC, MouseEvent, TouchEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import styles from './styles.module.scss'

interface ContainerProps {
  isPaused: boolean
  hud?: (section: SectionI) => React.ReactNode
  progressClass?: string
  padding: Padding
}

const Container: FC<ContainerProps> = ({ isPaused, hud, progressClass, padding }) => {
  const { loop, currentIndex, onPause, onPrevious, onSkip, onChange } = useContext<GlobalContextI>(GlobalContext)

  const [currentId, setCurrentId] = useState<number>(0)
  const [pause, setPause] = useState<boolean>(isPaused)

  const mousedownId = useRef<any>()
  const isMounted = useRef<boolean>(true)

  const { sections } = useContext<SectionsContextI>(SectionsContext)

  const setCurrentIdWrapper = useCallback((callback) => {
    setCurrentId(callback)
  }, [])

  useEffect(() => {
    if (onChange) onChange(currentId)
  }, [currentId, onChange])

  useEffect(() => {
    if (typeof currentIndex !== 'number') return

    if (currentIndex >= 0 && currentIndex < sections.length) {
      setCurrentIdWrapper(() => currentIndex)
    } else {
      console.error('index out of bounds', currentIndex)
    }
  }, [currentIndex, setCurrentIdWrapper, sections.length])

  useEffect(() => {
    if (typeof isPaused !== 'boolean') return
    setPause(isPaused)
  }, [isPaused])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const previous = useCallback(() => {
    setCurrentIdWrapper((prev) => (prev > 0 ? prev - 1 : prev))
  }, [setCurrentIdWrapper])

  const next = useCallback(() => {
    if (!isMounted.current) return
    if (loop) {
      setCurrentIdWrapper((prev) => (prev + 1) % sections.length)
    } else {
      setCurrentIdWrapper((prev) => {
        if (prev < sections.length - 1) return prev + 1
        return prev
      })
    }
  }, [loop, sections.length, setCurrentIdWrapper])

  const handleMouseDown = useCallback(() => {
    mousedownId.current = setTimeout(() => {
      onPause?.(currentId)
      setPause(true)
    }, 200)
  }, [currentId, onPause])

  const handleMouseUp = useCallback(
    (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
      event.preventDefault()
      mousedownId.current && clearTimeout(mousedownId.current)
      if (pause) {
        setPause(false)
      } else {
        const clientX =
          event.type === 'touchend' ? (event as TouchEvent).changedTouches[0].clientX : (event as MouseEvent).clientX
        if (clientX / window.innerWidth >= 0.5) {
          onSkip?.(currentId)
          next()
        } else {
          onPrevious?.(currentId)
          previous()
        }
      }
    },
    [currentId, next, onPrevious, onSkip, pause, previous]
  )

  const section = useMemo(() => {
    return sections[currentId]
  }, [currentId, sections])

  const style: any = {}
  if (padding.top) style.paddingTop = padding.top
  if (padding.left) style.paddingLeft = padding.left
  if (padding.right) style.paddingRight = padding.right

  return (
    <div
      className={styles.container}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      role="presentation"
    >
      <div className={styles.hud} style={style}>
        <ProgressArray
          className={progressClass}
          currentId={currentId}
          next={next}
          pause={pause}
          invert={section.invert}
        />
        {hud && hud(section)}
      </div>

      <div className={styles.section}>
        <section.content section={section} pause={pause} />
      </div>
    </div>
  )
}

export default Container
