import cx from 'classnames'
import { GlobalContext, SectionsContext } from './context'
import Progress from './Progress'
import { GlobalContextI, SectionsContextI } from './types'
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'

import styles from './styles.module.scss'

interface ProgressArrayProps {
  className?: string
  currentId: number
  pause: boolean
  next: () => void

  invert?: boolean
}

const ProgressArray: FC<ProgressArrayProps> = ({ className, currentId, next, pause, invert }) => {
  const [count, setCount] = useState<number>(0)
  const { defaultInterval, onSectionEnd, onSectionStart, onAllSectionsEnd } = useContext<GlobalContextI>(GlobalContext)
  const { sections } = useContext<SectionsContextI>(SectionsContext)

  const animationFrameId = useRef<number>()
  const countCopy = useRef<number>(count)
  const pauseCopy = useRef<boolean>(pause)
  const timestamp = useRef<number>()

  useEffect(() => {
    setCount(0)
    countCopy.current = 0
  }, [currentId, sections])

  useEffect(() => {
    pauseCopy.current = pause
    // When paused, we shouldn't proceed at all.
    // Our timestamp (which records the time since the last)
    // progress bar update) therefore needs to be reset when we exit
    // our paused state.
    if (!pause) timestamp.current = Date.now()
  }, [pause])

  const getCurrentInterval = useCallback(() => {
    if (typeof sections[currentId].duration === 'number') {
      return sections[currentId].duration as number
    }

    return defaultInterval
  }, [currentId, defaultInterval, sections])

  const sectionStartCallback = useCallback(() => {
    onSectionStart && onSectionStart(currentId, sections[currentId])
  }, [currentId, onSectionStart, sections])

  const sectionEndCallback = useCallback(() => {
    onSectionEnd && onSectionEnd(currentId, sections[currentId])
  }, [currentId, onSectionEnd, sections])

  const allSectionsEndCallback = useCallback(() => {
    onAllSectionsEnd && onAllSectionsEnd(currentId, sections)
  }, [currentId, onAllSectionsEnd, sections])

  const incrementCount = useCallback(() => {
    if (countCopy.current === 0) sectionStartCallback()
    if (pauseCopy.current) return
    setCount((count: number) => {
      const newTimestamp = Date.now()
      if (!timestamp.current) {
        timestamp.current = newTimestamp
        return count
      }
      const oldTimestamp = timestamp.current
      timestamp.current = newTimestamp
      const diff = newTimestamp - oldTimestamp
      const interval = getCurrentInterval()
      const newCount = Math.max(0, Math.min(100, count + 100 / (interval / diff)))
      countCopy.current = newCount
      return newCount
    })
    if (countCopy.current < 100) {
      animationFrameId.current = requestAnimationFrame(incrementCount)
    } else {
      sectionEndCallback()
      if (currentId === sections.length - 1) {
        allSectionsEndCallback()
      } else {
        // Avoid flashing the next progress bar as 'full' due to the render
        // delay in the use-effect intended to clear it, by clearing it now.
        setCount(0)
        countCopy.current = 0
      }
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current)
      }
      next()
    }
  }, [
    allSectionsEndCallback,
    currentId,
    getCurrentInterval,
    next,
    sectionEndCallback,
    sectionStartCallback,
    sections.length,
  ])

  useEffect(() => {
    if (!pause) {
      animationFrameId.current = requestAnimationFrame(incrementCount)
    }
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current)
      }
    }
  }, [currentId, incrementCount, pause])

  return (
    <div className={cx(styles.progressArr, className)}>
      {sections.map((_, i) => (
        <Progress
          key={i}
          count={count}
          width={1 / sections.length}
          active={i === currentId ? 1 : i < currentId ? 2 : 0}
          pause={pause}
          invert={invert}
        />
      ))}
    </div>
  )
}

export default ProgressArray
