import React, { FC } from 'react'
import { RichTextBlock } from 'prismic-reactjs'

import { MainImage, Padding } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props {
  className?: string

  image: MainImage
  title: Array<RichTextBlock>
  background: string
  padding: Padding
}
const Section: FC<Props> = ({ image, title, background, padding }) => {
  const srcsetMobile = [
    { img: image?.story_1x, size: '1x' },
    { img: image?.story_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  const style: any = { backgroundColor: background }
  if (padding.top) style.paddingTop = padding.top
  if (padding.bottom) style.paddingBottom = padding.bottom
  if (padding.left) style.paddingLeft = padding.left
  if (padding.right) style.paddingRight = padding.right

  return (
    <div className={styles.titlePage} style={style}>
      <div className={styles.inner}>
        <img className={styles.image} src={image.url} srcSet={srcsetMobile} alt={image?.alt || ''} />
        <PresentRichText className={styles.title} render={title} />
      </div>
    </div>
  )
}

export default Section
