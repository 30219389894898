import React, { FC, useCallback } from 'react'

import { track } from 'utils/analytics'

import { CallToAction } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import Button from 'components/NikhilButton'

import styles from './styles.module.scss'

// This is the CTA that appears on the side for desktop (not in the footer)
// See Footer for the one that appears there.
const CTA: FC<CallToAction> = ({ copy, text, url, analytics_event, analytics_module }) => {
  const handleCtaClick = useCallback(async () => {
    await track('click cta', { cta: analytics_event, module: analytics_module, location: 'desktop-left-column' })

    if (!url) return

    if (url && url.target === '_blank') {
      window.open(url.url, '_blank')?.focus()
    } else {
      window.location.href = url?.url || ''
    }
  }, [url, analytics_event, analytics_module])

  return (
    <div className={styles.cta}>
      <PresentRichText className={styles.copy} render={copy} />
      <Button className={styles.button} type="button" onClick={handleCtaClick}>
        {text || 'Get Started'}
      </Button>
    </div>
  )
}

export default CTA
