import React, { FC } from 'react'
import cx from 'classnames'

import { ServicesProvidedSlice } from '../../types'

import DesignerIcon from 'svgs/designer'
import ArchitectIcon from 'svgs/architect'
import StructuralEngineerIcon from 'svgs/contractor-2'
import ContractorIcon from 'svgs/contractor-3'
import BidReviewIcon from 'svgs/bid-review'
import BidNegotiationIcon from 'svgs/bid-negotiation'
import PermittingIcon from 'svgs/permitting'
import FinancingIcon from 'svgs/money'
import ZoningAnalysisIcon from 'svgs/zoning'
import PricingAndRoiAnalysisIcon from 'svgs/roi-analysis'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props {
  className?: string

  slice: ServicesProvidedSlice
}

const ServicesProvided: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.servicesProvided, className)}>
      <PresentRichText className={styles.title} render={slice.primary?.title} />
      <ul>
        {slice.primary?.designer && (
          <li className={styles.service}>
            <DesignerIcon />
            <span>{'Designer'}</span>
          </li>
        )}
        {slice.primary?.architect && (
          <li className={styles.service}>
            <ArchitectIcon />
            <span>{'Architect'}</span>
          </li>
        )}
        {slice.primary?.structural_engineer && (
          <li className={styles.service}>
            <StructuralEngineerIcon />
            <span>{'Structural Engineer'}</span>
          </li>
        )}
        {slice.primary?.contractor && (
          <li className={styles.service}>
            <ContractorIcon />
            <span>{'Contractor'}</span>
          </li>
        )}
        {slice.primary?.bid_review && (
          <li className={styles.service}>
            <BidReviewIcon />
            <span>{'Bid Review'}</span>
          </li>
        )}
        {slice.primary?.bid_negotiation && (
          <li className={styles.service}>
            <BidNegotiationIcon />
            <span>{'Bid Negotiation'}</span>
          </li>
        )}
        {slice.primary?.permitting && (
          <li className={styles.service}>
            <PermittingIcon />
            <span>{'Permitting'}</span>
          </li>
        )}
        {slice.primary?.financing && (
          <li className={styles.service}>
            <FinancingIcon />
            <span>{'Financing'}</span>
          </li>
        )}
        {slice.primary?.zoning_analysis && (
          <li className={styles.service}>
            <ZoningAnalysisIcon />
            <span>{'Zoning Analysis'}</span>
          </li>
        )}
        {slice.primary?.pricing_and_roi_analysis && (
          <li className={styles.service}>
            <PricingAndRoiAnalysisIcon />
            <span>{'Pricing and ROI Analysis'}</span>
          </li>
        )}
      </ul>
    </section>
  )
}

export default ServicesProvided
