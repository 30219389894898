import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import { QuoteSlice, CallToAction, Padding } from '../../types'

import useParticipants from '../../components/Participant/useParticipants'

import QuoteIcon from 'svgs/quote'

import PresentRichText, { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import Image from '../../components/Participant/Image'
import StoryCTA from '../../components/StoryCTA'

import styles from './styles.module.scss'

interface Props {
  className?: string

  slice: QuoteSlice
  callToAction: CallToAction

  padding: Padding
}

const Quote: FC<Props> = ({ className, slice, callToAction, padding }) => {
  const { findParticipant } = useParticipants()

  const participant = findParticipant(slice.primary?.participant_identifier || '')

  let quote = slice.primary?.story_quoted_text
  if (!hasTextPresent(quote)) {
    quote = slice.primary?.quoted_text
  }

  const style: any = { backgroundColor: slice.primary?.story_background || '' }
  if (padding.top) style.paddingTop = padding.top
  if (padding.bottom) style.paddingBottom = padding.bottom
  if (padding.left) style.paddingLeft = padding.left
  if (padding.right) style.paddingRight = padding.right

  const textStyle = useMemo(() => {
    // 410 is the static space calculated from the css
    const space = `${padding.top || '0px'} - ${padding.bottom || '0px'}`
    // return { fontSize: `unquote("clamp(1rem, (100vh - 410px - ${space})/8, 1.875rem)")` }
    return { fontSize: `clamp(1rem, (100vh - 525px - ${space})/8, 1.875rem)` }
  }, [padding])

  return (
    <div className={cx(styles.quote, className)} style={style}>
      <div className={styles.inner}>
        <PresentRichText className={styles.text} render={quote} style={textStyle} />
        <div className={styles.name}>
          {`- ${participant?.display_name}`}
          {!participant?.isCustomer && `, ${participant?.display_title}`}
        </div>
        <div className={styles.imagry}>
          <QuoteIcon className={styles.icon} color={slice.primary?.story_icon} />
          {participant && <Image className={styles.image} participant={participant} big />}
        </div>
        {callToAction?.url && (
          <>
            <div className={styles.buffer} />
            <StoryCTA className={styles.cta} {...callToAction} />
          </>
        )}
      </div>
    </div>
  )
}

export default Quote
