import React, { FC } from 'react'

interface Props {
  className?: string
  color?: string
}

const Svg: FC<Props> = ({ className, color }) => (
  <svg className={className} width="163" height="136" viewBox="0 0 163 136" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M119.483 135.5C112.598 135.5 106.253 131.585 103.283 125.51L84.1133 87.17C82.2233 83.39 81.2783 79.34 81.2783 75.155V14C81.2783 6.575 87.3533 0.5 94.7783 0.5H148.778C156.203 0.5 162.278 6.575 162.278 14V68C162.278 75.425 156.203 81.5 148.778 81.5H121.778L135.683 109.31C141.758 121.325 132.983 135.5 119.483 135.5ZM11.205 135.5C4.31998 135.5 -2.02502 131.585 -4.99503 125.51L-24.165 87.17C-26.055 83.39 -27 79.34 -27 75.155V14C-27 6.575 -20.925 0.5 -13.5 0.5H40.5C47.925 0.5 54 6.575 54 14V68C54 75.425 47.925 81.5 40.5 81.5H13.5L27.405 109.31C33.48 121.325 24.705 135.5 11.205 135.5Z"
      fill={color || 'currentColor'}
    />
  </svg>
)

export default Svg
