import React, { FC } from 'react'
import cx from 'classnames'

import { PictureSetSlice } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props {
  className?: string

  slice: PictureSetSlice
}

const PictureSet: FC<Props> = ({ className, slice }) => {
  // Don't let them use more than 5.
  // Filter out any that don't have a root image.
  const images = slice.items.filter((item) => item.image.url).slice(0, 5)

  // If there are no images, don't show this.
  if (images.length == 0) return null

  return (
    <section className={cx(styles.pictureSet, className)}>
      <PresentRichText className={styles.title} render={slice.primary?.title} />
      <div
        className={cx(styles.images, {
          [styles.single]: images.length == 1,
          [styles.double]: images.length == 2,
          [styles.triple]: images.length == 3,
          [styles.quadruple]: images.length == 4,
          [styles.quintuple]: images.length == 5,
        })}
      >
        {images.map((item) => {
          const srcsetDesktop = [
            { img: item.image, size: '1x' },
            { img: item.image?.desktop_2x, size: '2x' },
          ]
            .filter((img) => !!img.img)
            .map((img) => `${img.img?.url} ${img.size}`)
            .join(',')
          const srcsetMobile = [
            { img: item.image?.mobile_1x, size: '1x' },
            { img: item.image?.mobile_2x, size: '2x' },
          ]
            .filter((img) => !!img.img)
            .map((img) => `${img.img?.url} ${img.size}`)
            .join(',')

          return (
            <div key={item.image.url} className={styles.imgSlot}>
              <img
                className={cx(styles.image, styles.desktop)}
                src={item.image.url}
                srcSet={srcsetDesktop}
                alt={item.image?.alt || ''}
              />
              <img
                className={cx(styles.image, styles.mobile)}
                src={item.image.url}
                srcSet={srcsetMobile}
                alt={item.image?.alt || ''}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default PictureSet
