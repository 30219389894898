import React, { FC, ReactNode, useMemo, useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { RichTextBlock } from 'prismic-reactjs'

import { prepareBoth } from 'utils/analyticsV2'

import {
  MainImage,
  StoryImage,
  PictureSetSlice,
  QuoteSlice,
  ContentSlice,
  // ServicesProvidedSlice,
  Slice as SliceType,
  Padding,
  CallToAction,
} from './types'

import { SectionI } from '../Roundup/InstaStory/types'
import InstaStory from '../Roundup/InstaStory'
import Section from '../Roundup/components/Section'

import useParticipants from './components/Participant/useParticipants'

import Title from './storySections/Title'
import Introduction from './storySections/Introduction'
import Quote from './storySections/Quote'
import Content from './storySections/Content'
import PictureSet from './storySections/PictureSet'
import ServicesProvided from './storySections/ServicesProvided'
import Hud from './StoryHud'

import styles from './Story.module.scss'

const VALID_SLICE_TYPES = ['quote', 'content', 'picture_set', 'services_provided']

const [trackClick] = prepareBoth({ family: 'project-case-studies', screen: 'project-case-study' })

const StorySection: FC<{ section: { bgClass: string; slice: () => ReactNode } }> = ({ section }) => {
  return (
    <Section className={cx('tw-text-white', styles.section)} webview>
      {section.slice()}
    </Section>
  )
}

function showPictureSetSlice(slice: SliceType): boolean {
  // If it isn't a picture_set, then we have no claim to deny showing it.
  if (slice.slice_type == 'picture_set') {
    const pSlice = (slice as unknown) as PictureSetSlice
    // Don't show unless we have a story_image to show.
    if (!pSlice.primary?.story_image?.url) return false
  }
  return true
}

interface Props {
  className?: string

  image: MainImage
  title: Array<RichTextBlock>
  deck: Array<RichTextBlock>
  deckEnd: Array<RichTextBlock>
  context: Array<RichTextBlock>

  introImage: StoryImage

  titleBackground: string
  introBackground: string

  slices: Array<SliceType>
  padding: Padding

  callToAction: CallToAction
}

const Story: FC<Props> = ({
  image,
  title,
  deck,
  deckEnd,
  introImage,
  titleBackground,
  introBackground,
  slices,
  padding,
  callToAction,
}) => {
  const { findParticipant } = useParticipants()
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const rnwv = typeof window !== 'undefined' ? (window as any).ReactNativeWebView : null
    if (rnwv) {
      const customLog = (...args) => {
        rnwv.postMessage(`log:${args.join(', ')}`)
      }
      window.console.log = customLog
      window.console.info = customLog
      window.console.debug = customLog
      window.console.warn = customLog
      window.console.error = customLog
    }
  }, [])

  // Any images we want preloaded must be defined in here.
  useEffect(() => {
    const preloadImage = (img) => {
      // Create a new image object, just to get things loading.
      const image = new Image()
      image.src = img
      // Don't bother waiting for it to load.
    }

    // Toss the title image in; it is probably loaded but..
    preloadImage(image.story_1x.url)
    preloadImage(image.story_1x.url)

    // Load introduction images
    preloadImage(introImage.url)
    preloadImage(introImage.image_2x.url)

    // Process slice images
    slices.forEach((slice) => {
      // Only consider slices we care about
      if (!VALID_SLICE_TYPES.includes(slice.slice_type || '')) return

      if (slice.slice_type === 'quote') {
        const tSlice = slice as QuoteSlice
        if (tSlice.primary) {
          // Find the participant image.
          const participant = findParticipant(tSlice.primary.participant_identifier)
          if (participant) {
            preloadImage(participant.image.big_1x.url)
            preloadImage(participant.image.big_2x.url)
          }
        }
      } else if (slice.slice_type === 'content') {
        const tSlice = slice as ContentSlice
        tSlice.items.forEach((item) => {
          preloadImage(item.image.mobile_1x.url)
          preloadImage(item.image.mobile_2x.url)
        })
      } else if (slice.slice_type === 'picture_set') {
        const tSlice = slice as PictureSetSlice
        if (tSlice.primary) {
          preloadImage(tSlice.primary.story_image.url)
          preloadImage(tSlice.primary.story_image.image_2x.url)
        }
      } else if (slice.slice_type === 'services_provided') {
        // No images for this slice.
      }
    })
  }, [image, introImage, slices, findParticipant])

  const renderTitle = useCallback(
    function RenderTitle() {
      return <Title image={image} title={title} background={titleBackground} padding={padding} />
    },
    [image, title, titleBackground, padding]
  )
  const renderIntroduction = useCallback(
    function RenderIntroduction() {
      return (
        <Introduction image={introImage} deck={deck} deckEnd={deckEnd} background={introBackground} padding={padding} />
      )
    },
    [introImage, deck, deckEnd, introBackground, padding]
  )
  const renderSlice = useCallback(
    (slice) => {
      return function RenderSlice() {
        if (VALID_SLICE_TYPES.includes(slice.slice_type || '')) {
          return (
            <>
              {slice.slice_type === 'quote' ? (
                <Quote slice={slice} padding={padding} callToAction={callToAction} />
              ) : slice.slice_type === 'content' ? (
                <Content slice={slice} padding={padding} callToAction={callToAction} />
              ) : slice.slice_type === 'picture_set' ? (
                <PictureSet slice={slice} padding={padding} callToAction={callToAction} />
              ) : slice.slice_type === 'services_provided' ? (
                <ServicesProvided slice={slice} padding={padding} callToAction={callToAction} />
              ) : null}
            </>
          )
        }
        return null
      }
    },
    [padding, callToAction]
  )

  const sections = useMemo(() => {
    const sliceObjs = slices
      .map((slice, i) => {
        // We need to know beforehand what slices there are.
        // So now is the time to filter out any that shouldn't display.
        if (!showPictureSetSlice(slice)) return null

        return {
          content: StorySection,
          bgClass: '',
          invert: slice.slice_type != 'picture_set',
          key: `slice_${i}`,
          slice: renderSlice(slice),
        }
      })
      .filter((slice) => !!slice) as Array<SectionI>

    return [
      {
        content: StorySection,
        bgClass: '',
        invert: true,
        key: 'titleSection',
        slice: renderTitle,
      },
      {
        content: StorySection,
        bgClass: '',
        invert: true,
        key: 'introSection',
        slice: renderIntroduction,
      },
      ...sliceObjs,
    ]
  }, [renderTitle, renderIntroduction, renderSlice, slices])

  // Allow outside events to prompt certain actions.
  useEffect(() => {
    if (!window) return
    ;(window as any).CaseStudyStory = {
      back: () => setCurrentIndex((prev) => Math.max(0, prev - 1)),
      forward: () => setCurrentIndex((prev) => Math.min(sections.length - 1, prev + 1)),
    }
  }, [sections])

  const handleEnd = useCallback(async () => {
    const rnwv = (window as any).ReactNativeWebView
    if (!rnwv) return
    trackClick({ code: 'finish' })
    rnwv.postMessage('close')
  }, [])

  const handleClose = useCallback(() => {
    const rnwv = (window as any).ReactNativeWebView
    if (!rnwv) return
    trackClick({ code: 'close' })
    rnwv.postMessage('close')
  }, [])

  const handlePause = useCallback(
    (i: number) => {
      trackClick({ code: 'pause', section: (sections[i] as any).key })
    },
    [sections]
  )

  const handlePrevious = useCallback(
    (i: number) => {
      trackClick({ code: 'previous', section: (sections[i] as any).key })
    },
    [sections]
  )

  const handleSkip = useCallback(
    (i: number) => {
      trackClick({ code: 'skip', section: (sections[i] as any).key })
    },
    [sections]
  )

  const handleChange = useCallback((i: number) => {
    setCurrentIndex(i)
  }, [])

  const renderHud = useCallback(
    (section: SectionI) => {
      return <Hud section={section} onClose={handleClose} />
    },
    [handleClose]
  )

  return (
    <InstaStory
      sections={sections}
      hud={renderHud}
      progressClass={styles.progress}
      defaultInterval={6000}
      onAllSectionsEnd={handleEnd}
      onPause={handlePause}
      onPrevious={handlePrevious}
      onSkip={handleSkip}
      currentIndex={currentIndex}
      onChange={handleChange}
      padding={padding}
    />
  )
}

export default Story
