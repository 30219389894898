import React, { FC, useMemo } from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

interface ProgressProps {
  active: number
  count: number
  pause: boolean
  width: number
  invert?: boolean
}

const Progress: FC<ProgressProps> = ({ active, count, pause, width, invert }) => {
  const progressWrapperStyle = useMemo(() => {
    return {
      width: `${width * 100}%`,
      opacity: pause ? 0 : 1,
    }
  }, [width, pause])

  const progressStyle = useMemo(() => {
    switch (active) {
      case 2:
        return { width: '100%' }
      case 1:
        return { transform: `scaleX(${count / 100})` }
      default:
        return { width: 0 }
    }
  }, [active, count])

  return (
    <div className={cx(styles.progress, { [styles.invert]: invert })} style={progressWrapperStyle}>
      <div className={cx(styles.inner, { [styles.invert]: invert })} style={progressStyle} />
    </div>
  )
}

export default Progress
