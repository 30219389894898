import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor" fillRule="evenodd">
      <path d="M4 22v-2h16v2Zm8-3-4-4 1.4-1.4 1.6 1.55v-6.3L9.4 10.4 8 9l4-4 4 4-1.4 1.4L13 8.85v6.3l1.6-1.55L16 15ZM4 4V2h16v2Z" />
    </g>
  </svg>
)

export default Svg
