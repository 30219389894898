import React, { FC } from 'react'
import cx from 'classnames'
import { RichTextBlock } from 'prismic-reactjs'

import { Image, Slice as SliceType } from './types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import ImageList from './components/Participant/ImageList'

import Quote from './sections/Quote'
import Content from './sections/Content'
import PictureSet from './sections/PictureSet'
import ServicesProvided from './sections/ServicesProvided'

import styles from './Article.module.scss'

const Slice: FC<SliceType> = (props) => {
  const { ...slice } = props

  if (['quote', 'content', 'picture_set', 'services_provided'].includes(slice.slice_type || '')) {
    return (
      <>
        {slice.slice_type === 'quote' ? (
          <Quote slice={slice} />
        ) : slice.slice_type === 'content' ? (
          <Content slice={slice} />
        ) : slice.slice_type === 'picture_set' ? (
          <PictureSet slice={slice} />
        ) : slice.slice_type === 'services_provided' ? (
          <ServicesProvided slice={slice} />
        ) : null}
      </>
    )
  }
  return null
}

interface Props {
  className?: string

  image: Image
  title: Array<RichTextBlock>
  deck: Array<RichTextBlock>
  context: Array<RichTextBlock>

  slices: Array<SliceType>
}

const Article: FC<Props> = ({ className, image, title, deck, context, slices }) => {
  const srcsetDesktop = [
    { img: image, size: '1x' },
    { img: image?.desktop_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')
  const srcsetMobile = [
    { img: image?.mobile_1x, size: '1x' },
    { img: image?.mobile_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  return (
    <div className={cx(styles.article, className)}>
      <section className={styles.lead}>
        <img
          className={cx(styles.image, styles.desktop)}
          src={image.url}
          srcSet={srcsetDesktop}
          alt={image?.alt || ''}
        />
        <img className={cx(styles.image, styles.mobile)} src={image.url} srcSet={srcsetMobile} alt={image?.alt || ''} />
        <PresentRichText className={styles.context} render={context} />
        <PresentRichText className={styles.title} render={title} />
        <div className={styles.deckFrame}>
          <ImageList className={styles.images} />
          <PresentRichText className={styles.deck} render={deck} />
        </div>
      </section>
      {slices.map((slice, i) => (
        <Slice key={i} {...slice} />
      ))}
    </div>
  )
}

export default Article
