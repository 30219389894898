import React, { FC } from 'react'
import cx from 'classnames'

import { ContentSlice } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props {
  className?: string

  slice: ContentSlice
}

const Content: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.content, className)}>
      <PresentRichText className={styles.title} render={slice.primary?.title} />
      {slice.items.map((item) => {
        const srcsetDesktop = [
          { img: item.image, size: '1x' },
          { img: item.image?.desktop_2x, size: '2x' },
        ]
          .filter((img) => !!img.img)
          .map((img) => `${img.img?.url} ${img.size}`)
          .join(',')
        const srcsetMobile = [
          { img: item.image?.mobile_1x, size: '1x' },
          { img: item.image?.mobile_2x, size: '2x' },
        ]
          .filter((img) => !!img.img)
          .map((img) => `${img.img?.url} ${img.size}`)
          .join(',')

        return (
          <>
            <PresentRichText className={styles.text} render={item.content} />
            <img
              className={cx(styles.image, styles.desktop)}
              src={item.image.url}
              srcSet={srcsetDesktop}
              alt={item.image?.alt || ''}
            />
            <img
              className={cx(styles.image, styles.mobile)}
              src={item.image.url}
              srcSet={srcsetMobile}
              alt={item.image?.alt || ''}
            />
          </>
        )
      })}
    </section>
  )
}

export default Content
