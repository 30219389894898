import React, { FC, PropsWithChildren } from 'react'

import { Participant } from '../../types'
import { useContext, createContext, useMemo, useCallback } from 'react'

interface IParticipantContext {
  customers: Array<Participant>
  advisors: Array<Participant>
}

export const ParticipantContext = createContext<IParticipantContext>({
  customers: [],
  advisors: [],
})

export const ParticipantsManager: FC<PropsWithChildren<{ participants: IParticipantContext }>> = ({
  children,
  participants,
}) => <ParticipantContext.Provider value={participants}>{children}</ParticipantContext.Provider>

export default function useParticipants(): {
  customers: Array<Participant>
  advisors: Array<Participant>
  all: Array<Participant>
  findParticipant: (identifier: string) => (Participant & { isCustomer: boolean }) | undefined
} {
  const { advisors, customers } = useContext(ParticipantContext)

  const all = useMemo(() => [...customers, ...advisors], [customers, advisors])

  const handleFindParticipant = useCallback(
    (identifier: string) => {
      const found = all.find((participant) => participant.identifier == identifier)
      if (found) return { ...found, isCustomer: !!customers.find((c) => c.identifier == found.identifier) }
    },
    [all, customers]
  )

  return {
    customers,
    advisors,
    all,
    findParticipant: handleFindParticipant,
  }
}
