import React, { FC } from 'react'
import cx from 'classnames'

import { PictureSetSlice, CallToAction, Padding } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import StoryCTA from '../../components/StoryCTA'

import styles from './styles.module.scss'

interface Props {
  className?: string

  slice: PictureSetSlice
  callToAction: CallToAction
  padding: Padding
}

const PictureSet: FC<Props> = ({ className, slice, callToAction, padding }) => {
  const srcset = [
    { img: slice.primary?.story_image, size: '1x' },
    { img: slice.primary?.story_image?.image_2x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  const style: any = {}
  if (padding.bottom) style.paddingBottom = padding.bottom
  if (padding.left) style.paddingLeft = padding.left
  if (padding.right) style.paddingRight = padding.right

  const fadeStyle: any = { height: `calc(8rem + ${padding.top || '0px'})` }

  return (
    <div className={cx(styles.pictureSet, className)}>
      <div className={styles.fade} style={fadeStyle} />
      <div className={styles.inner} style={style}>
        <PresentRichText className={styles.title} render={slice.primary?.title} />
        {callToAction?.url && <StoryCTA className={styles.cta} {...callToAction} />}
      </div>
      <img
        className={styles.image}
        src={slice.primary?.story_image?.url}
        srcSet={srcset}
        alt={slice.primary?.story_image?.alt || ''}
      />
    </div>
  )
}

export default PictureSet
