import * as Sentry from '@sentry/browser'

if (process.env.ENABLE_SENTRY) {
  const release = `realm-app-${process.env.HEROKU_RELEASE_VERSION || 'dev'}`
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release,
    allowUrls: [
      'analytics.realmhome.com',
      'bam.nr-data.net',
      'cdn.mxpnl.com',
      'connect.facebook.net',
      'edge.fullstory.com',
      'fast.wistia.com',
      'googleads.g.doubleclick.net',
      'js-agent.newrelic.com',
      'js.intercomcdn.com',
      'm.stripe.com',
      'maps.googleapis.com',
      's.pinimg.com',
      'script.hotjar.com',
      'widget.intercom.io',
      'www.google-analytics.com',
      'www.googleadservices.com',
      'www.googletagmanager.com',
    ],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'TypeError: cancelled',
      'TypeError: Cancelled',
      'TypeError: Illegal invocation',
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
    ],
  })
}
