import React, { FC } from 'react'
import cx from 'classnames'

import { QuoteSlice } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import Participant from '../../components/Participant'

import styles from './styles.module.scss'

interface Props {
  className?: string

  slice: QuoteSlice
}

const Quote: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.quote, className)}>
      <PresentRichText className={styles.text} render={slice.primary?.quoted_text} />
      <Participant className={styles.participant} identifier={slice.primary?.participant_identifier || ''} />
    </section>
  )
}

export default Quote
